// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'
// import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import getRandomArbitraryInt from '../../methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ResourceRenderer */
const ResourceRenderer = ({ resource }) => {
  const { title, cover, text } = resource

  return (
    <div className="slider-resource">
      <h3 className="as-h4">{title}</h3>
      <p>{text}</p>
      <GatsbyImage
        style={{ minHeight: '6rem', marginBottom: 0 }}
        className="as-paragraph"
        image={getImage(cover)}
        durationFadeIn={getRandomArbitraryInt(150, 450)}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------- Type
// ----------------------------------------------------------------------------
/* eslint-disable */
ResourceRenderer.propTypes = {
  dummy1: PropTypes.string,
  // document: PropTypes.object,
  // Img: PropTypes.object,
  // loadedFunc: PropTypes.object,
  // datePosition: PropTypes.object,
  // title: PropTypes.object,
  // cover: PropTypes.object,
  // publishedAt: PropTypes.object,
  // abstract: PropTypes.object,
}

ResourceRenderer.defaultProps = {
  dummy2: 'default',
}
/* eslint-enable */

// ----------------------------------------------------------------------------
// ----------------------------------------------------------- Compose & Export
// ----------------------------------------------------------------------------
/** Compose ala FP style - Add Box, Link */
export default ResourceRenderer
