// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import pure from 'recompose/pure'
import { connect } from 'react-redux'

import isUndefined from 'lodash/isUndefined'

import classnames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import smallKey from '../../methods/smallKey'

import ArrowLeft from '../svg/arrow-left'
import ArrowRight from '../svg/arrow-right'

import ResourceRenderer from '../slider-resource-renderer'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Slider */
const Slider = (props) => {
  const { mediaState, resources, show = [2, 3, 3], type = 'standard' } = props
  let currentMediaStateWidth = 'isDesktop'
  if (isUndefined(mediaState) === false) {
    if (isUndefined(mediaState.currentWidth) === false) {
      currentMediaStateWidth = mediaState.currentWidth
    }
  }

  const sliderConf = {
    slidesToScroll: 1,
    slidesToShow:
      currentMediaStateWidth === 'isMobile'
        ? show[0]
        : currentMediaStateWidth === 'isTablet'
        ? show[1]
        : show[2],
    infinite: true,
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  return (
    <div
      className={classnames('slider as-paragraph', type)}
      style={{ maxWidth: 'unset' }}
    >
      <Carousel {...sliderConf}>
        {resources.map((resource) => (
          <ResourceRenderer key={smallKey()} resource={resource} />
        ))}
      </Carousel>
    </div>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // dispatch => ({})
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedSlider = compose(
  withState, // Add state
  pure // Make it a pure stateless functional component
)(Slider)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedSlider
